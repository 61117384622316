/**
 * Styles targeting the Cardiology Bootstrap Subtheme
 *
 * Most borrowed from previous implementation created by Viviana Espinosa
 * /sites/all/themes/cardiology/scss/cardiology.scss
 *
 */

/* =Branding
----------------------------------------------------------*/

body {
  background: url(../images/heartimage_LIGHT_052416-gray_1800px.jpg) top center no-repeat;
}

.brand__lockup img {
  width: auto;
  height: 84px;
}

#page {
  background: rgba(255,255,255,0.7);
}

/* =Navigation
----------------------------------------------------------*/

#primary-nav {
  background-color: transparent;
}

#primary-nav .level-1 {
  width: auto;
  padding:8px 2.6%;
}

// Students, Residents, and Fellows
#primary-nav  .menu-mlid-1617 {
  width: 26%;
}

// Faculty and Staff
#primary-nav  .menu-mlid-1618,
#primary-nav  .menu-mlid-1619 {
  width: 17%;
}

#active-third-level-nav {
  background: transparent;
}

/* =Sidebar
----------------------------------------------------------*/

.information-sidebar .make-an-appointment {
  font-size: 18px;
  text-align: center;
}

.information-sidebar .phone {
  font-size: 25px;
  font-family: $wcm-bold;
  text-align: center;
}

.information-sidebar .btn--wcm {
  margin-bottom: 20px;
}

.information-sidebar .address {
  text-align: center;
}

.information-sidebar .address__institution,
.information-sidebar .address__division {
  font-weight: 700;
}

/* =Profile Tables
----------------------------------------------------------*/

.display-name {
  font-weight: 700;
  line-height: 1.4;
}

.views-field-field-phone,
.views-field-field-pops-profile-link {
  white-space: nowrap;
}

.view-current-projects h3 {
  margin-bottom: 10px;
}

.trial-title {
  font-size: 20px;
}

/* =Footer Navigation
----------------------------------------------------------*/

.footer-nav {
  @include breakpoint($md) {
    margin: 0 0 20px;
  }
}

.footer-nav .level-1 {
  @include breakpoint($md) {
    width: 20%;
    float: left;
    padding: 0 10px 5px;
  }
}

/* =Our Physicians
----------------------------------------------------------*/

table {
  border-top: 7px solid;
  margin: 0px;
  font-size: 12px;
  @include breakpoint($sm) {
    font-size: 14px;
  }
}

tr {
  border-bottom: 1px solid #B2B2B2;
}

tr td {
  padding: 5px;
}

th {
  border-bottom: 3px solid #ccc;
  padding-right: 1em;
  text-align: left;
  width: 11%;
}

th td tr{
  padding: 5px;
  @include breakpoint($sm) {
    padding: 2px;
  }
}

tbody{
  line-height: 1.3em;
}

tr.even,
tr.odd {
  background: rgb(250, 250, 250);
}

.mobile-table tbody tr:nth-child(odd) {
  background: none;
}

.mobile-table tr.odd {
  color: #000;
  background: none;
}

.views-field.views-field-field-professional-title,
.views-field-field-pops-profile-link {
  display: none;
  @include breakpoint($sm) {
    width: 15%;
    display: table-cell;
  }
}

.views-field.views-field-field-pops-profile-link {
  width: 7%;
}

.views-field-field-profile-image {
  display: none;
  @include breakpoint($md) {
    display: table-cell;
    width: 7%;
  }
}

#edit-field-last-name-value {
  background-position: 100% 13px;
  height: 30px;
  margin-bottom: 0px;
  @include breakpoint($md) {
    background-position: 100% 5px;
    height: 25px;
  }
}

#views-exposed-form-profiles-panel-pane-1 {
  padding: 10px 5px;
  margin: 0 auto;
  margin-bottom: 20px;
  @include breakpoint($sm) {
    border: 1px dotted gray;
  }
  @include breakpoint($md) {
   padding: 10px 280px;
   margin-bottom: 40px;
  }
}

.views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 1.3em;
}

.wcmc-profile-table{
  max-width: 60%;
}

.pane-profiles-panel-pane-3 {
  margin: 20px 0 0;
}

.pane-profiles-panel-pane-3 table {
  margin: 20px 0 0;
}

/* =Teasers
----------------------------------------------------------*/

.view-teaser {
  margin-bottom: 25px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  @include clearfix();
}

.teaser__image {
  float: right;
  width: 25%;
  margin: 0;
}

.teaser__title {
  font-family: "1898Sans-Bold",sans-serif;
  line-height: 1.2;
  margin: 0;
  padding-bottom: 5px;
}

.teaser__detail {
  float: left;
  width: 75%;
  padding: 0 20px 0 0;
}

/* =Misc
----------------------------------------------------------*/

.field-label {
  font-weight: bold;
}

/* Responsive you-tube video */
.media-youtube-video {
  position:relative;
  padding-bottom:56.25%;
  padding-top:30px;
  margin-bottom: 20px;
  height:0;
  overflow:hidden;
}
.media-youtube-video iframe {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}

.hero-section .pane-bundle-image p {
  color: $wcm-med-gray;
  font-size: 13px;
  font-style: italic;
  @include breakpoint($sm) {
    float: right;
    width: 500px;
    text-align: right;
  }
}

.hero-section .pane-bundle-image img,
.hero-image {
  width: 100%;
  height: auto;
  margin: 0 0 20px !important;
}

.news-featured-image img {
  width: auto;
  max-width: 100%;
}

.video {
  margin-bottom: 40px;
}

.video__title {
  margin-bottom: 10px;
}

#views-exposed-form-current-projects-panel-pane-1,
#views-exposed-form-studies-panel-pane-1 {
  clear: both;
  border: 1px dotted gray;
  padding: 15px 0 15px 25px;
  margin: 0 auto;
  margin-bottom: 20px;
  @include breakpoint($md) {
   margin-bottom: 40px;
  }
  .form-submit {
    @include breakpoint($sm) {
      margin-top: 33px;
    }
  }
}

.site-main .col-md-12 table {
  width: 100%;
  margin: 20px 0;
  border-top: 5px solid $wcm-border-gray;
  tr {
    border-bottom: 1px solid $wcm-border-gray;
  }
}

.ctools-collapsible-processed .ctools-collapsible-handle h2 {
    position: relative;
    font-size: 18px;
    line-height: 20px;
    color: #cf4520;
    border: 1px solid #ddd;
    border-width: 3px 0 0;
    cursor: pointer;
    margin: 0;
    padding: 15px 55px 15px 10px;
    font-weight: 600;
}

.ctools-collapsible-processed .ctools-collapsible-handle h2:after {
    content: '-';
    width: 50px;
    display: block;
    position: absolute;
    top: 15px;
    right: 0;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    border-left: 3px solid #ddd;
}

.ctools-collapsible-processed.ctools-collapsed .ctools-collapsible-handle h2:after {
    content: '+';
}

.ctools-collapsible-container .ctools-toggle {display:none;
}

.headshot-grid{
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
