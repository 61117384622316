
 * Styles created for the Cardiology Homepage
 *
 * Most borrowed from previous implementation created by Viviana Espinosa
 * /sites/all/themes/cardiology/scss/cardiology.scss
 *
 *

.front #page {
  background: transparent;
}

/* =Accolades
----------------------------------------------------------*/

#accolades {
  margin: 0 auto;
  background: rgba(255,255,255,0.7);
  img {
    float: right;
    &.best-doctors{
        width: 70px;
      }
      &.best-hospitals{
        width: 150px;
      }
    }
  .award {
    font-family: $wcm-bold;
  }
  .actions{
    text-align: center;
    p {
      line-height: 1.8rem;
      color: $wcm-dark-orange;
      text-align: center;
    }
  } //end actions - still on accolades
  @include breakpoint($sm) {
    .award {
      color: $wcm-dark-orange;
      font-size: 2.3rem;
    }
    .actions{
      >.give a {
        color: #fff;
      }
    }
  }
  @include breakpoint($md) {
    .award {
      font-size: 1.6em;
    }
  }
}

.awards > .row > div,
.actions {
  @include breakpoint($sm) {
    height: 150px;
  }
}

.awards img,
.award,
.actions__cta {
  @include breakpoint($sm) {
    @include v-center();
  }
}

.actions {
  @include breakpoint($xs) {
    padding: 20px 0 0;
  }
}

.actions p {
  @include breakpoint($xs) {
    padding: 0 0 5px;
  }
}

.appointment {
  font-family: $wcm-bold;
}

.phone {
  font-size: 18px;
  font-family: $wcm-bold;
  margin: 0;
  padding: 10px 0;
  @include breakpoint($md) {
    font-size: 24px;
  }
}

.give {
  padding: 10px 0 0;
}

.give a {
  font-size: 18px;
  color: $wcm-dark-orange;
  &:after {
    content: "   \203A";
    color: #ffc72c;
    font-size: 18px;
    text-decoration: none;
  }
}

/* =Carousel
----------------------------------------------------------*/

.thumbnail-controller {
  background: rgba(255,255,255,0.7);
}

/* =Body Content
----------------------------------------------------------*/
.front-page-body{
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;

  @include breakpoint($md) {
    max-width: 80%;  
    
  }
}

/* =Doormat Navigation
----------------------------------------------------------*/

.doormat-nav {
  margin: 0 auto;
  padding: 20px 0;
  text-align: center;
  background: rgba(255,255,255,0.7);
  border-top: 1px solid $wcm-white;
  @include clearfix();

  .container {
    max-width: 80rem;

    .seven-cols{
      .col-sm-1{
        @include breakpoint($sm) {
          width: 14.28%;
        }
      }
    }

  }

  .doormat-nav__tile span {
    display: block;
    text-align: center;
  }
  
  .doormat-nav__tile .cta-icon {
      width: 40px;
      height: 40px;
      fill: #e87722;
      color: #cf4520;
  }
  
}

